import React, { useState } from 'react';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import '../styles/contacts.scss';

const Contacts = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState([]);

  const metaData = {
    title: 'Contact me',
    excerpt: 'If you have some feedback or proposal, you can always find me.'
  };

  const getTime = () =>
    new Date().toLocaleString('bg-BG', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
      hour12: false,
      timeZone: 'Europe/Sofia'
    });

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors([]);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
    setErrors([]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSpinner(true);
    const data = { email, message, time: getTime() };

    // fetch('http://localhost:5000/pantaleycom/us-central1/app/api/contacts', {
    fetch('https://us-central1-pantaleycom.cloudfunctions.net/app/api/contacts', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          setErrors(res.errors);
        }

        if (res.isEmailSend) {
          setIsSent(true);
        }

        setSpinner(false);
        return res;
      })
      .catch((errors) => {
        console.log(errors);
      });
  };

  return (
    <Layout postData={metaData}>
      <h1>Contacts</h1>
      {!isSent ? (
        <form onSubmit={handleSubmit}>
          <label>
            e-mail:
            <br />
            <input
              className={errors.some((e) => e.param === 'email') ? 'invalid' : ''}
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
          </label>
          <br />
          <label>
            Message
            <br />
            <textarea
              className={errors.some((e) => e.param === 'message') ? 'invalid' : ''}
              type="text"
              name="message"
              value={message}
              onChange={handleMessageChange}
            />
          </label>
          <br />
          <input type="submit" value="submit" />
          {errors.map((error, i) => (
            <div className="errors" key={i}>
              {error.msg}
            </div>
          ))}
        </form>
      ) : (
        <p>Thank you</p>
      )}
      {spinner ? <Spinner /> : ''}
    </Layout>
  );
};

export default Contacts;
